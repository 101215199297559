body {
  margin: 0px;
  padding: 0px;
}

.mainLayout {
  width: 100%;
  display: flex;

  .inputPanel {
    flex: 0 0 20%;

    margin: 5px;
    _border: 1px #f0f solid;
  }

  .settings {
    padding: 2px;
    border: 1px #444 solid;

    .title {
      font-weight: bold;
      text-align: center;
      padding-top: 2px;
      padding-bottom: 10px;
    }

    .sub-title {
      font-weight: bold;
      text-align: center;
      padding-top: 2px;
      padding-bottom: 10px;
    }
  }

  .inputs-title {
    font-weight: bold;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 2px;

    display: flex;

    div {
      flex: 1 0;
    }

    button {
      flex: 0 0;
      white-space: nowrap;
    }
  }

  .lineGroupInput {
    margin-top: 5px;
    padding: 2px;
    border: 1px #444 solid;

    &.invalid {
      border: 1px #f00 solid;
      background-color: #fdd;
    }

    .title {
      text-align: center;
      _padding-top: 2px;
      padding-bottom: 2px;
    }

    .bottom-row {
      display: flex;
      justify-content: space-between;

      .button-remove {
        width: 22px;
        height: 22px;
      }

      .color {
        width: 40px;
        height: 20px;

        border: 1px #000 solid;
      }
    }
  }

  .buttonAdd {
    margin-top: 5px;
    margin-left: 2px;
    width: 22px;
    height: 22px;
  }

  .outputCanvas {
    flex: 1 0 50%;

    height: 700px;

    margin: 5px;
    border: 1px #000 solid;

    .draggable {
      width: 100%;
      height: 100%;

      &:active {
        cursor: grabbing;
      }
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.info-text {
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;
  font-style: italic;
}
